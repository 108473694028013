// .archiveSwiper {
//     visibility: hidden;
// }

// .artext-wrapper {
//     width: 100%;
//     display: flex;
//     justify-content: space-evenly;
//     padding-top: 50px;
// }

.archiveGallery {
    // padding-top: 90px;
    // top: 0px;
    // left: 0px;
    // padding: 40px;
    // display: grid;
    // gap: 80px;
    // display: fl;




    // align-items: center;
    // justify-content: center;
    // position: absolute;
    // height: 105vh;

    // top: 190px;
    // width: 1898px;
    // min-height: 105vh;
    // padding: 50px;
    // padding-left: 300px;
    // padding-bottom: 200px;
    // overflow: auto;
    // background: white;
    // visibility: visible;
    // transform: translate3d(0px, 0px, 0px);


    .archiveGalleryImages {
        width: 800px;
        max-width: 900px;
        justify-content: center;
        // display: none !important;
        // opacity: 1 !important;
        // visibility: hidden !important;
        // width: unset !important;
        // background-color: black;
    }


    .galImage {
        // cursor: pointer;
        position: absolute;
        // opacity: 1;
        // width: 60% !important;

        // &:hover {
        //     z-index: 100000;
        //     transition: 0.2s opacity ease-out;
        // }
    }

    #archiveSwiper {
        width: 2px !important;
        height: 400px;
    }


    // @keyframes orbitSequenceA {
    //     0%    { transform: rotate(0deg) translateX(50px) rotate(0deg) }
    //     20%   { transform: rotate(90deg) translateX(50px) rotate(-90deg) }
    //     60%   { transform: rotate(180deg) translateX(50px) rotate(-180deg) }
    //     80%   { transform: rotate(270deg) translateX(50px) rotate(-270deg) }
    //     100%  { transform: rotate(360deg) translateX(25px) rotate(-360deg)  }
    //     // from { transform: rotate(0deg) translateX(50px) rotate(0deg) }
    //     // to   { transform: rotate(360deg) translateX(25px) rotate(-360deg) }
    // }

    // @keyframes orbitSequenceB {
    //     from   { transform: rotate(180deg) translateX(25px) rotate(-180deg) }
    //     to { transform: rotate(0deg) translateX(50px) rotate(0deg) }
    // }

    .galImageWrapper {

        &:hover {
            z-index: 100000;
            transition: 1.5s opacity ease-out;
        }
    }

    // .galImageWrapper:first-child {
    //     position: relative;
    //     top: -125px;
    //     left: 20px;
    // }

    // .galImageWrapper:nth-child(odd) {
    //     // animation: orbitSequenceB 5s linear infinite backwards;
    // }

    // .galImageWrapper:nth-child(2n) {
    //     position: relative;
    //     left: -100px;
    //     top: 90px;
    //     margin-right: 50px;
    //     // animation: orbitSequenceA 5s infinite backwards;
    // }

    // .galImageWrapper:nth-child(3) {
    //     position: relative;
    //     left: -20px;
    //     top: 50px;
    // }

    // .galImageWrapper:nth-child(5) {
    //     position: relative;
    //     left: -120px;
    // }

    // .galImageWrapper:nth-child(7) {
    //     position: relative;
    //     left: -120px;
    // }

}


// // Desktop
// @media only screen and (min-width: 1025px) {}

// // Tablet
// @media only screen and (max-width: 1024px) {
//     .swiper-container {
//         width: 900px;
//     }

//     #galImage {
//         height: 400px;
//     }
// }

// // Mobile
// @media only screen and (max-width: 767px) {
//     .swiper-container {
//         width: 768px;
//     }

//     #galImage {
//         height: 250px;
//     }

//     .archiveGallery {
//         grid-template-columns: auto auto auto auto;
//         top: 190px;
//         min-height: 100vh;
//         padding-left: unset;
//         padding-bottom: unset;
//         max-width: 350px;
//     }
// }