.index {
    display: flex;
}


.index-container {
    width: 50%;
}

.index-media-container {
    width: 50%;
    opacity: 0;


    .bg-video {
        height: 100%;
        width: 49%;
        top: 0;
        left: 50%;
        position: absolute;
        overflow: hidden;
        z-index: 0;
        direction: ltr;
        
        iframe {
            width: 420px !important;
        }

        #idx-video {
            position: absolute;
            top: 48%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        }

        #idx-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }  
}

.loadingContainer {
    min-height: 95vh;
    height: 100%;
    bottom: -50px !important;
    top: -50px !important;
    background-color: white;
    overflow: hidden !important;
    position: relative;
    text-align: center;
    align-items: center;
    z-index: 20;
    max-height: 2304px;
    margin-top: -40px;
    padding-bottom: 50px;
}

.loadingScreen {
    width: 100%;
    // background-color: black;
    // color: white;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.progressText{
    display: flex;
    flex-direction: column;
}

.loading-script {
    width: 490px;
    padding: 53px 0px 8px 20px;

    white-space: break-spaces;
}

.scriptText {
    font-size: 10.8px;
}


.react-typewriter-text {
    font-size: 10.8px !important;
    font-weight: 500;
    // line-height: 1.2;
    white-space: break-spaces;
}


@media only screen and (max-width: 768px) {

    .loading-script {
        padding: 60px 20px 20px 20px !important;
    }

    .studio-index {
        flex-direction: column !important;
        max-width: 350px;
        padding: 0 20px !important;
    }

    .index-para {
        width: 350px;
        max-width: 350px;
    }

    .idx-iframe {
        right: unset !important;
        max-width: 350px;
    }

    .featured-title {
        color: white;
    }
}

.studio-index {
    max-width: 100%;
    padding: 10px 36px 0px 20px;
    display: flex;
    flex-direction: row;
    gap: 40px;

    .col-1-of-2 {
        float: left;
        width: 100%;
        overflow: hidden;
        opacity: 1;
    }

    .col-1-of-2:first-child {
        max-width: 440px;
    }
}


.index-para {
    opacity: 1;
}

.featured-title {
    background-color: black;
    color: transparent;
    text-decoration: none;
    text-transform: uppercase;
    padding: 2px;
    width: fit-content;


    &:hover {
        color: white;
    }
}

.idx-video-wrapper {
    padding: 56.25% 0 0 0; 
    position: relative;
}

.idx-iframe {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0 !important;
    top: 0;
    margin-top: -10px;

}


#idxGIFOne {
    display: none;
}

#idxGIFTwo {
    display: none;
}

#idxGIFThree {
    display: none;
}


#featuredTitleOne, #featuredTitleTwo, #featuredTitleThree {
    opacity: 0;
}



#descOne, #descTwo, #descThree {
    opacity: 0;
}

// Desktop
@media only screen and (max-width: 1025px) {
    .idx-video, .idx-image {
        padding: 80px 0;
    }
}

// Tablet
@media only screen and (max-width: 1024px){
    .loadingContainer {
        margin-top: -41px;
    }

    .loadingScreen {
        max-width: 900px;
        padding: 20px;
    }

    .progressBar {
        max-width: 900px;
    }

    .progressText {
        max-width: 900px !important;
        // font-size: 8px !important;
        margin-left: auto;
        margin-right: auto;
    }
}

// Mobile
@media only screen and (max-width: 767px){
    .index {
        max-width: 350px;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .index-container {
        width: 100%;
        max-width: 350px;
        padding: 5px;
    }

    .loading-script {
        max-width: 350px !important;
        width: 100%;
    }

    .studio-index {
        max-width: 350px;
        width: 100%;
    }

    .index-media-container {
        width: 100%;
        height: 400px;
        max-width: 350px;
        padding: 20px;
        padding-top: 100px;
        position: relative;
        margin: 0 auto;

        #idx-video {
            width: 350px !important;
            height: 100% !important;
            margin: 0 auto;
        }

        .bg-video {
            left: 0; 
            right: 0; 
            padding: 0 20px !important;
            margin-left: auto; 
            margin-right: auto; 
            width: 350px;
            // bottom: 0;
            position: absolute;
            // margin: 0 auto;

        }
    }

    .featured-title {
        color: white;
    }

    .loadingContainer {
        margin-top: -41px;
    }

    .loadingScreen {
        padding: 20px;
        margin: 0 auto;
    }

    .progressBar {
        max-width: 330px;
    }

    .progressText {
        max-width: 330px !important;
        font-size: 8px !important;
        // margin-left: auto;
        // margin-right: auto;
    }

    .footer-container{
        margin-bottom: 0.75rem;
    }

}