@font-face {
    font-family: "DrukWide-heavy";
    src: url("../fonts/DrukWide-Heavy.ttf");
  }
  
  @font-face {
      font-family: "Grotesque-light";
      src: url("../fonts/GeneralGrotesqueMono-Thin.ttf");
  }
  
  @font-face {
      font-family: "Grotesque-regular";
      src: url("../fonts/GeneralGrotesqueMono-Regular.ttf");
  }
  
  @font-face {
      font-family: "Grotesque-bold";
      src: url("../fonts/GeneralGrotesqueMono-Book.ttf");
  }
  
  @font-face {
      font-family: "Grotesque-heavy";
      src: url("../fonts/GeneralGrotesqueMono-Heavy.ttf");
  }
  
  
  @font-face {
      font-family: "Grotesque-demi";
      src: url("../fonts/GeneralGrotesqueMono-Demi.ttf");
  }
  
  
  @font-face {
      font-family: "Supply-light";
      src: url("../fonts/Supply-UltraLight.otf");
  }
  
  @font-face {
      font-family: "Supply-regular";
      src: url("../fonts/Supply-Regular.otf");
  }
  
  @font-face {
      font-family: "Supply-bold";
      src: url("../fonts/GeneralGrotesqueMono-Book.ttf");
  }
  
  
  @font-face {
      font-family: "Roboto";
      src: url("../fonts/Roboto-Regular.ttf");
  }