.info {
    text-transform: uppercase;
    margin: 0;
    position: fixed;
    top: 40%;
    left: 50%;
    width: 480px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    line-height: 1.2;
    word-spacing: 0.7em;
    padding-top: 100px;
    text-align: justify;
    min-height: 310px;

    .info-title {
        margin-top: 5rem;
        font-family: 'Grotesque-demi';
        opacity: 0;
    }

    .info-value {
        opacity: 0;
    }

    .react-typewriter-text {
        font-size: 10.8px !important;
        // text-align: justify !important;
        line-height: 1.2rem;
    }
}


// Desktop
@media only screen and (min-width: 1025px) {
}

// Tablet
@media only screen and (max-width: 1024px){
    .info {
        
    }

}

// Mobile
@media only screen and (max-width: 767px){
    .info {
        max-width: 350px;
    }
}
