.loading {
    text-transform: uppercase;
    position: fixed;
    top: 50%;
    left: 51.1%;
    transform: translate(-50%, -50%);
}

.single-project {
    padding-top: 20px;
}

.swiper {
    padding: 60px 0px !important;
    height: auto;
    max-height: auto;
    .swiper-slide {
        display: flex;
        justify-content: center;
        height: auto;
    }
}

.swiper-pagination-fraction {
    // bottom: 0px !important;
    bottom: 70px !important;
    text-align: center !important;
    width: 33.33% !important;
}


.text-wrapper {
    display: flex;
    justify-content: space-around;
    text-align: center;
    // margin-top: -25px;
    padding-top:40px;
    // position: absolute;

    p, span {
        text-transform: uppercase !important;
    }

    .sProjectButton button {
        padding: 20px !important;
        cursor: pointer;
    }
}


pre.caret-underscore > span {
    animation: blink 1s step-end infinite;
    border-left: 8px solid black;
    margin-left: 5px;
}

.inputForm {
    width: 40%;
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    // color: black;
    caret-color: transparent;

    &:focus {
        outline: none;
        caret-color: transparent;
    }
}

#player {
    width: 1200px !important;
}
.buttonWrapper {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-transform: uppercase;
    // padding: 5px;       
    // padding-right: 8px; 
    // margin: 10px;
    // background-color: black;

}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-transform: uppercase;
}

input[type="text" i] {
    font-family: 'Grotesque-Regular';
    font-size: 10.8px;
    padding: unset;
    padding-bottom: 3px;
}

@keyframes blink {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: black;
    }
}

label {
    text-transform: uppercase;
    padding-top: 12px
}

.form {
    margin-top: -2px;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 15%;
    gap: 40px;
}

.prev-arrow, .next-arrow {
    font-family: "Supply-Regular";
    font-size: 2rem;
    z-index: 200000 !important;
}


.arrow-left {
    rotate: 180deg !important;
}

.prev-arrow {
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 40%;
}

.next-arrow {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 40%;
}

.video {
    width: 80vw;
    max-width: 1200px;
    aspect-ratio: 16/9;
    > iframe {
        width: 100%;
        height: 100%;
    }
}

.text-wrapper {
    button {
        cursor: pointer;
    }
}


// Desktop
@media only screen and (min-width: 1025px) {

}

// Tablet
@media only screen and (max-width: 1024px){
    .swiper-container {
        width: 900px;
    }

    .video-wrapper img {
        height: 400px;
    }

}

// Mobile
@media only screen and (max-width: 767px){
    .video {
        width: 75vw;
    }

    .swiper-container {
        width: 768px;
    }

    .video-wrapper img {
        height: 250px;
    }
}