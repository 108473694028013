.diary {
    margin: 5rem 2.5rem auto 2.5rem;
    @media screen and (max-width: 750px) {
        margin-top: 1.5em;        
    }
}

.diaryImage {
    object-fit: contain;
}

.masonry {
    > div {
        @media screen and (max-width: 750px) {
            gap: 2rem !important;  
        }
    }
}