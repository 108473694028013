.projects-featured {
    padding: 0;
    padding-top: 60px;

    .react-typewriter-pointer {
        background-color: white !important;
    }
}


.projects {
    display: grid;
    // gap: 30px;
    // grid-template-columns: repeat(3, minmax(0, 1fr));
    // grid-template-columns: auto auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    // place-items: start;
    
    text-transform: uppercase;
    opacity: 1;
    transition: opacity 0.5s linear;
    padding: 0px;
    // padding-top: 250px;
    // padding-left: 20px;
    // padding-right: 20px;


    li {
        list-style: none;
        padding: 0;
        margin: none;
    }
    
    a {
        text-decoration: none;
    }

    .title-box {
        padding: 2vw 2vw;
        opacity: 1;
        .title{
            margin-top: 0.25rem;
        }
    }
}

.projects > * {
    width: 100%;
}

.projects > [style^='--aspect-ratio']::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    // padding-bottom: calc(100% / (var(--aspect-ratio)));
    padding-top: 75%;
}

.featured-wrapper {
    // min-width: 758px;
    width: 758px;
    /* height: 800px; */
    position: relative;
    margin: 0 auto;
    // /* padding-top: 60px;

    .text-content {
        z-index: 1;
        width: 50%;
        height: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;

        .featured-projects {
            width: fit-content;
            text-transform: uppercase;
            background: black;
            color: black;
            padding: 2px;            
        
            &:hover {
                color: white;
            }
        }
        
    }
}


.banner-image {
    position: relative;
    background-size: cover !important;
    display: block;
    width: 29vw;
    height: 19vw;
    .banner-gif {
        opacity: 0;
        transition: .3s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:hover  {
        .banner-gif {
            opacity: 1;
        }
    }
}

// Desktop
@media only screen and (min-width: 1025px) {
}

// Tablet
@media only screen and (max-width: 1024px){
    .projects {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .banner-image{
            width: 45.2vw;
            height: 27vw;
        }
    }
}

// Mobile
@media only screen and (max-width: 767px){
    .projects {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        .banner-image{
            width: 96vw;
            height: 59vw;
        }
    }
}


.projectsFilter{
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    > li {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
        &.active{
            outline: 1px solid black;
        }
        button{
            padding: 0.25rem;
        }
        &:hover{
            background-color: black;
            color: white;
        }

    }
}
body.white-content {
    .projectsFilter{
        > li {
            &:hover{
                background-color: white;
                color: black;
            }
            &.active{
                outline: 1px solid white;
            }
        }
    }
}