.headerMobile {
    text-transform: uppercase;
    font-size: 12px;
    overflow: hidden;

    height: calc( 100svh - (20px * 2) );
    display: flex;
    flex-direction: column;
 
    padding: 5px 20px;
    a {
        text-decoration: none;
    }

    .spacer {
        flex-grow: 1;
    }
}

.logoHeader {
    font-family: "DrukWide-heavy";
    padding: 15px;

    a {
        text-decoration: none;
    }
}

.top-nav, .bottom-nav {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
}

.bottomest {
    padding-top: 40px;
}

.darkMode {
    padding: 20px 0px;
    margin-left: -18px;
    
    button {
        background: none;
        color: inherit;
        border: none;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        text-transform: uppercase;
        padding: 5px 5px;
        margin: 5px 14px;
    }
}


// Desktop
@media only screen and (min-width: 1025px) {
}

// Tablet
@media only screen and (max-width: 1024px){

}

// Mobile
@media only screen and (max-width: 767px){

    .logoHeader {
        max-width: 350px !important;
        padding: 20px !important;
    }

    .header-container {
        display: none;
    }
}
