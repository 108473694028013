// dark mode
.white-content {
    background-color: black;
    color: white !important;

    .header a {
        color: white;
    }

    .featured-title {
        background-color: white;
    }

    .featured-title:hover {
        color: black;
    }

    .react-typewriter-pointer {
        background-color: white !important;
    }

    .header a.active {
        background-color: white !important;
        color: black;
    }

    .header-container {
        background: black !important;
    }

    .footer-container {
        background-color: black;
    }
    
    @media only screen and (max-width: 767px){
        .featured-title {
            color: black;
        }
    }

    .arrow-logo, .arrow-left, .arrow-right {
        fill: white !important;
        z-index: 102222222220000;
    }

    .header a, .right-nav button {

        &:hover {
            background-color: white !important;
            color: black;
        }
    }

    .loadingContainer {
        background-color: black;
    }

    .progressBar {
        border: 1px solid white !important;
        background-color: black !important;
    }

    .progressFill {
        background-color: white !important;
    }
}

// light mode
.dark-content {
    background-color: white;
    color: black; 
}

.header-container {
    position: sticky;
    z-index: 3;
    background: white;
    top: 0%;
    width: 100%;
    height: 40px;
}

.header {
    text-transform: uppercase;
    // font-size: 12px;
    overflow: hidden;
    display: flex;
    padding-top: 3px;
    padding-left: 10px;
    
    @media only screen and (max-width: 768px) {
        display: none;
    }

}

.header a.active {
    background-color: black !important;
    color: white;
}

.header a {
    float: left;
    padding: 5px 5px;
    margin: 5px 5px;

    text-decoration: none;
    color: black;

    &:hover {
        background-color: black !important;
        color: white;
    }
}

.left-nav {
    float: left;
    font-family: "DrukWide-heavy";
}

.center-nav {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0px 5px;
    margin-top: -2px;
    gap: 12px;
}

.right-nav {
    float: right;
    display: flex;
    margin-top: -3.5px;

    button {
        background: none;
        color: inherit;
        border: none;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        text-transform: uppercase;
        padding-left: 5px;       
        padding-right: 8px; 
        margin: 5px 14px;

        &:hover {
            background-color: black !important;
            color: white;
        }
    }
}