@use './Typography';

body {
    overflow-y: scroll;
    overflow-x: hidden;
    /* box-sizing: border-box; */
    margin: 0 auto;
    font-family: "Grotesque-regular";
    font-size: 10.8px;
    scroll-behavior: smooth !important;
}

pre {
    font-family: "Grotesque-regular";
}

@keyframes cursor {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.react-typewriter-pointer {
    margin-left: 5px;
    padding: 0px 4px !important;
	opacity: 0;
	animation: cursor 1s infinite;
}

#sideNav {
    background-color: white;
}
.headerMobile  a {
    color: black;
}
.logoHeader a {
    color: black;
}

#navToggle{
    background-color: black;
    width: 1.25rem;
    height: 1.25rem;
    position: fixed;
    top: 1.5rem;
    right: 35px;
    z-index: 20;
    display: none;
    @media (max-width: 768px) {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }
}

body.white-content {
    #sideNav {
        background-color: black;
    }
    .logoHeader  a {
        color: white;
    }
    .headerMobile  a {
        color: white;
    }
    #navToggle{
        background-color: white;
    }
}