.footer-container {
    display: grid;
    // grid-template-columns: auto auto auto auto;
    grid-template-columns: 360px 360px 360px auto;
    // grid-gap: 10px;
    position: fixed;
    bottom: 0px;
    padding: 0.5rem;
    width: 100%;
    // max-width: 1280px;
    text-transform: uppercase;
    white-space: pre;
    background-color: white;
    z-index: 1;

    .footerLink {
        text-align: center§ ;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}


// Desktop
@media only screen and (min-width: 1025px) {
}

// Tablet
@media only screen and (max-width: 1024px){
    .footer-container {
        grid-template-columns: auto auto auto auto;
    }

}

// Mobile
@media only screen and (max-width: 767px){
    .footer-container {
        grid-template-columns: auto auto auto auto;
    }
}
